import { hasWellSupportedEmojis, isiOS } from "@multimediallc/web-utils/modernizr"
import { OutgoingMessageType } from "@multimediallc/web-utils/types"
import twemoji from "@twemoji/api"
import { addColorClass } from "../../cb/colorClasses"
import { PMControlBar } from "../../cb/components/pm/pmControlBar"
import { ReactComponentRegistry } from "../../cb/components/ReactRegistry"
import { MentionUserList } from "../../cb/components/userMentions/MentionUserList"
import { updateUserMention, UserMentionAutocompleteModal } from "../../cb/components/userMentions/userMentionAutocompleteModal"
import { hideRulesModal, maybeShowRulesModal } from "../../cb/components/userMenus/ui/rulesModal"
import { ChatButtonHolder, ChatButtonType } from "../../cb/ui/chatButtonHolder"
import { NewMessageLine } from "../../cb/ui/newMessageLine"
import { windowResizeInProgress } from "../../cb/ui/responsiveUtil"
import { ScrollDownButton } from "../../cb/ui/scrollDownButton"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { isNotLoggedIn } from "../auth"
import { handleFocusedChatInput } from "../chatPageAction"
import { roomCleanup, roomLoaded } from "../context"
import { Debouncer, DebounceTypes } from "../debouncer"
import { Component } from "../defui/component"
import { isElementInViewport, stopScrollMomentum } from "../DOMutils"
import { EmoticonAutocompleteModal } from "../emoticonAutocompleteModal"
import { EventRouter, ListenerGroup } from "../events"
import { addChatHistoryPageActionsScollListener, isChatScrollingPageactionsActive, isScrollDownNoticeActive } from "../featureFlagUtil"
import { insertByTimestamp } from "../messageToDOM"
import { addPageAction } from "../newrelic"
import { styleUserSelect } from "../safeStyle"
import { ShortcodeAutocompleteModal } from "../shortcodeAutocompleteModal"
import { errorBehindShortcode, parseOutgoingMessage } from "../specialoutgoingmessages"
import { createInputField, createInputForm, inputFieldMargin, maxInputChat, maxInputPm, maxMessageHistory, SendButtonVariant } from "../theatermodelib/chatTabContents"
import { scrollToTheaterModePlayer } from "../theatermodelib/theaterModePlayer"
import { userChatSettingsUpdate } from "../theatermodelib/userActionEvents"
import { i18n } from "../translation"
import { getMoreHistoryMessages, standardEmojiRequest } from "../userActionEvents"
import { videoModeHandler } from "../videoModeHandler"
import { createLogMessage, styleUsernameMention } from "./messageToDOM"
import { resetPureChatHideTimeouts } from "./pureChatUtil"
import { openTipCalloutRequest } from "./userActionEvents"
import type { LibraryMediaDock, SelectedMediaDock } from "../../cb/components/pm/mediaDock"
import type { IChatContents } from "../chatRoot"
import type { IRoomContext } from "../context"
import type { CustomInput } from "../customInput"
import type {
    IOutgoingMessageHandlers,
    IOutgoingShortcodeMessage,
    ITipRequestMessage, 
} from "../specialoutgoingmessages"

// region DOM Creation
const inputDivHeight = 36
export const fvmButtonGap = 4

function createMessageListWrapper(): HTMLDivElement {
    // the wrapper handles the scrolling and padding so that other parts of the project can
    // call `getBoundingClientRect` on the window
    const messageListWrapper = document.createElement("div")
    messageListWrapper.className = "msg-list-wrapper-fvm"
    messageListWrapper.style.width = "100%"
    messageListWrapper.style.boxSizing = "border-box"
    if (isScrollDownNoticeActive()) {
        messageListWrapper.style.minHeight = "40px"
        messageListWrapper.style.overflowY = "auto"
        messageListWrapper.style.overflowX = "hidden"
    } else {
        messageListWrapper.style.overflow = "auto"
    }
    messageListWrapper.style.flex = "1"
    return messageListWrapper
}

function createNoticeList(): HTMLDivElement {
    const m = document.createElement("div")
    m.style.width = "100%"
    m.className = "notice-list-fvm"
    styleUserSelect(m, "text")
    m.style.cursor = "text"
    return m
}

function createMessageList(): HTMLDivElement {
    const m = document.createElement("div")
    m.className = "msg-list-fvm"
    m.style.width = "100%"
    m.style.overflowX = "hidden"
    styleUserSelect(m, "text")
    m.style.cursor = "text"
    return m
}

export function createInputDiv(): HTMLDivElement {
    const inputDiv = document.createElement("div")
    addColorClass(inputDiv, "chat-input-div")
    inputDiv.style.minHeight = `${inputDivHeight}px`
    inputDiv.style.height = `${inputDivHeight}px`
    inputDiv.style.boxSizing = "border-box"
    inputDiv.style.fontSize = "12px"
    inputDiv.style.borderRadius = "0 0 2px 2px"
    inputDiv.style.display = "grid" // can move to flex when all our supported browsers support `gap` with flex layouts
    inputDiv.style.gridTemplateColumns = "minmax(14px, 1fr) auto" // input form and button holder
    inputDiv.style.columnGap = `${inputFieldMargin}px`
    inputDiv.style.padding = `0 ${inputFieldMargin / 3}px 0 ${inputFieldMargin}px`
    return inputDiv
}

export function handleTipButtonClick(ev: MouseEvent): void {
    openTipCalloutRequest.fire({})
    ev.stopPropagation()
    ev.preventDefault()
}

export function createTipButton(listenerGroup?: ListenerGroup): HTMLSpanElement {
    const span = document.createElement("span")
    addColorClass(span, "tip-button")
    span.title = i18n.tipCAPS
    span.innerText = i18n.tipCAPS
    span.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"
    span.style.fontSize = "12px"
    span.style.padding = "4px 7px 3px"
    addColorClass(span, "tip-button-gradient")
    span.style.borderRadius = "4px"
    span.style.boxSizing = "border-box"
    span.style.cursor = "pointer"
    span.style.display = "inline-block"
    span.style.overflow = "hidden"
    span.style.textOverflow = "ellipsis"
    span.style.whiteSpace = "nowrap"
    span.style.minHeight = "24px"
    addEventListenerPoly("mousedown", span, ev => {
        ev.stopPropagation()
    })
    addEventListenerPoly("click", span, handleTipButtonClick)

    const roomLoadedListener = roomLoaded.listen(context => {
        span.style.display = context.dossier.isAgeVerified ? "inline-block" : "none"
    })
    if (listenerGroup !== undefined) {
        roomLoadedListener.addTo(listenerGroup)
    }

    return span
}

function createMediaDockButton(onClick: () => void): HTMLSpanElement {
    const span = document.createElement("span")
    span.style.cursor = "pointer"
    span.style.display = "inline-block"
    span.style.height = "15px"
    span.style.minWidth = "15px"
    span.dataset["paction"] = "TheaterChat"
    span.dataset["pactionName"] = "UploadPhoto"
    span.dataset["testid"] = "send-image-button"
    span.onclick = onClick

    const img = document.createElement("img")
    img.src = `${STATIC_URL_ROOT}tsdefaultassets/mediaDock/uploadBackground-lighter.svg`
    img.style.width = "100%"
    img.style.height = "100%"
    span.appendChild(img)
    return span
}

export function createEmojiButton(isPmChat: boolean): HTMLSpanElement {
    const span = document.createElement("span")
    span.textContent = "😁"
    span.style.cursor = "pointer"
    span.style.fontSize = "18px"
    span.style.lineHeight = "18px"
    span.dataset["pactionName"] = "EmojiClick"
    span.dataset.testid = "emoji-button"
    span.classList.add(isPmChat ? "fullvideoEmojiButtonPm" : "fullvideoEmojiButtonChat")

    if (!hasWellSupportedEmojis()) {
        twemoji.parse(span, { className: "emojiButton" })
        span.style.fontSize = "15px"
        span.style.lineHeight = "15px"
    }
    return span
}
// endregion

export class ChatTabContents extends Component<HTMLDivElement> implements IChatContents {
    public removeMessagesForUserEvent = new EventRouter<string>("removeMessageHtml", { reportIfNoListeners: false })
    public scrolledToBottom = new EventRouter<void>("scrolledToBottom")
    // tracks if chat is scrolled up, ignoring scroll changes caused by window resizing
    private wasScrolledUp: boolean
    public messageList = createMessageList()
    private noticeList = createNoticeList()
    public messageListWrapper = createMessageListWrapper()
    public customInputField: CustomInput
    public formSubmitted = new EventRouter<string>("formSubmitted")
    public inputDiv: HTMLDivElement
    private inputForm: HTMLFormElement
    private buttonHolder: ChatButtonHolder
    private emojiButton: HTMLSpanElement
    private tipButton: HTMLSpanElement
    private messageCounter = 0
    private emoticonAutocompleteModal: EmoticonAutocompleteModal
    private shortcodeAutocompleteModal: ShortcodeAutocompleteModal | undefined
    private userMentionAutocompleteModal: UserMentionAutocompleteModal | undefined
    private listenerGroup = new ListenerGroup()
    private earliestMessageId: string | undefined
    protected isPmChatContents = this.pmOtherUser !== undefined
    protected currentRoomContext: IRoomContext | undefined
    private newMessageNotice?: NewMessageLine
    private scrollDownButton?: ScrollDownButton
    private scrollToBottomDebouncer = new Debouncer(() => this.scrollToBottom(), { bounceLimitMS: 0, debounceType: DebounceTypes.throttle })

    // only for PM chat
    public libraryMediaDock?: LibraryMediaDock
    public selectedMediaDock?: SelectedMediaDock
    public mediaDockButton?: HTMLElement
    private pmControlBar?: PMControlBar

    constructor(protected outgoingHandlers: IOutgoingMessageHandlers, private inPrivateRoom: () => boolean, private isConversationShowing: () => boolean, protected pmOtherUser?: string) {
        super()

        this.element.id = "FVChatTabContents"
        this.element.style.fontSize = "12px"
        this.element.style.overflow = "" // ensure chat modals still show up if changing this, notably autocompletes
        this.element.style.display = "flex"
        this.element.style.flexDirection = "column"
        this.element.classList.add(this.isPmChatContents ? "FullvideoChatDivPm" : "FullvideoChatDivChat")
        addColorClass(this.element, "hasDarkBackground")

        this.messageListWrapper.appendChild(this.noticeList)
        this.messageListWrapper.appendChild(this.messageList)
        if (this.isPmChatContents) {
            this.pmControlBar = new PMControlBar(true, pmOtherUser!) // eslint-disable-line @typescript-eslint/no-non-null-assertion
            this.element.appendChild(this.pmControlBar.element)
        }
        this.element.appendChild(this.messageListWrapper)

        this.buildChatInput()

        if (isScrollDownNoticeActive()) {
            this.scrollDownButton = new ScrollDownButton({
                scrollToBottom: () => this.scrollToBottom(),
                bottomStyle: () => `${this.totalInputHeight() + 4}px`,
            })
            this.addChild(this.scrollDownButton)

            this.newMessageNotice = new NewMessageLine({
                getUnreadCount: () => this.scrollDownButton?.getUnreadCount(),
                isConversationShowing: () => this.isConversationShowing(),
                isScrolledUp: () => this.isScrolledUp(),
                setParentScrollTop: (oldScrollTop: number) => this.setScrollTop(oldScrollTop),
                scrollParentDiv: this.messageListWrapper,
            })

            // Clear new notice line(only on active chat window) and show latest chat to avoid having different scroll & new line positions
            // when switching between theatre/fullscreen purechat and spit mode chat.
            videoModeHandler.changeVideoMode.listen(() => {
                this.newMessageNotice?.remove()
                this.scrollToBottom()
            }).addTo(this.listenerGroup)
        }

        this.wasScrolledUp = false
        const onChatScroll = () => {
            // should still load more messages whenever top is shown
            const scrollAmount = this.messageListWrapper.scrollTop
            if (this.isPmChatContents && scrollAmount === 0 && this.isScrolledUp()) {
                getMoreHistoryMessages.fire()
            }
            // don't track scrolling triggered by resize events
            if (windowResizeInProgress) {
                return
            }
            if (!this.isScrolledUp() && this.wasScrolledUp) {
                this.scrolledToBottom.fire()
            }
            this.wasScrolledUp = this.isScrolledUp()
        }

        const chatScrollDebouncer = new Debouncer(
            onChatScroll,
            { bounceLimitMS: 50, debounceType: DebounceTypes.debounce },
        )

        addEventListenerPoly("scroll", this.messageListWrapper, () => {
            chatScrollDebouncer.callFunc()
            this.undebouncedOnScrollChange()
        })
        if (isChatScrollingPageactionsActive() && !this.isPmChatContents) {
            addChatHistoryPageActionsScollListener(this.messageListWrapper, this.messageList)
        }

        this.listenerGroup.add(roomLoaded.listen((context) => {
            this.currentRoomContext = context
            this.element.style.fontSize = context.dossier.userChatSettings.fontSize
            this.setLineHeight()
        }))

        roomCleanup.listen(() => {
            this.customInputField.clearText()
            this.customInputField.blur()
            this.scrollDownButton?.hideElement()
            hideRulesModal()
        }).addTo(this.listenerGroup)

        this.listenerGroup.add(userChatSettingsUpdate.listen(userChatSettings => {
            this.element.style.fontSize = userChatSettings.fontSize
            this.setLineHeight()
        }))

        this.emoticonAutocompleteModal = new EmoticonAutocompleteModal({
            inputElement: this.customInputField,
            leftOffset: 8,
            rightOffset: 167,
        })
        this.inputDiv.appendChild(this.emoticonAutocompleteModal.element)
        this.emoticonAutocompleteModal.afterDOMConstructedIncludingChildren()

        if (!this.isPmChatContents) {
            this.userMentionAutocompleteModal = new UserMentionAutocompleteModal({
                inputElement: this.customInputField,
                leftOffset: 8,
                rightOffset: 167,
            })
            this.inputDiv.appendChild(this.userMentionAutocompleteModal.element)
            this.userMentionAutocompleteModal.afterDOMConstructedIncludingChildren()
            updateUserMention.listen(() => {
                styleUsernameMention(this.messageList)
            }).addTo(this.listenerGroup)

            this.shortcodeAutocompleteModal = new ShortcodeAutocompleteModal({
                inputElement: this.customInputField,
                leftOffset: 8,
                rightOffset: 207,
            }, this.isPmChatContents)
            this.inputDiv.appendChild(this.shortcodeAutocompleteModal.element)
            this.shortcodeAutocompleteModal.afterDOMConstructedIncludingChildren()
        }

        this.emoticonAutocompleteModal.element.classList.add(this.isPmChatContents ? "fullvideoEmoticonAutocompleteModalPm" : "fullvideoEmoticonAutocompleteModalChat")
        this.emoticonAutocompleteModal.element.dataset["testid"] = "emoticon-autocomplete-modal"
    }

    private buildChatInput(): void {
        const maxLength = this.isPmChatContents ? maxInputPm : maxInputChat
        this.inputDiv = createInputDiv()
        this.inputDiv.dataset["paction"] = "TheaterChat"
        this.inputForm = createInputForm()
        this.customInputField = createInputField(
            () => this.sendMessageFromInput(),
            maxLength,
            this.isPmChatContents ? "fullvideoInputFieldPm" : "fullvideoInputFieldChat",
        )

        this.buildButtonHolder()

        this.inputForm.appendChild(this.customInputField.element)
        this.inputDiv.appendChild(this.inputForm)
        this.inputDiv.appendChild(this.buttonHolder.element)
        this.element.appendChild(this.inputDiv)

        this.addInputListeners()
    }

    private buildButtonHolder(): void {
        this.buttonHolder = new ChatButtonHolder({ columnGap: `${fvmButtonGap}px` })

        if (this.isPmChatContents) {
            this.mediaDockButton = createMediaDockButton(() => {this.onMediaDockButtonClick()})
            this.buttonHolder.addButton(this.mediaDockButton, ChatButtonType.Icon)
        }

        this.emojiButton = createEmojiButton(this.isPmChatContents)
        this.buttonHolder.addButton(this.emojiButton, ChatButtonType.Icon)

        const sendButtonRoot = document.createElement("span")
        const SendButton = ReactComponentRegistry.get("SendButton")
        new SendButton({
            "onClick": () => {this.customInputField.submit()},
            "isPm": this.isPmChatContents,
            "variant": SendButtonVariant.DraggableCanvas,
        }, sendButtonRoot)
        this.buttonHolder.addButton(sendButtonRoot, ChatButtonType.Text)

        this.tipButton = createTipButton(this.listenerGroup)
        this.buttonHolder.addButton(this.tipButton, ChatButtonType.Text)
    }

    private onMediaDockButtonClick(): void {
        addPageAction("PMPhotoButtonClicked")

        if (!isNotLoggedIn()) {
            if (maybeShowRulesModal(this)) {
                return
            }
            this.libraryMediaDock?.toggle()
            this.scrollToBottom()
        }
    }

    private addInputListeners(): void {
        addEventListenerPoly("mousedown", this.inputDiv, (ev) => {
            if (ev.target === this.inputDiv || ev.target === this.inputForm) {
                this.customInputField.focus()
                ev.preventDefault()
            }
        })

        addEventListenerPoly("focus", this.customInputField.element, (ev) => {
            if (maybeShowRulesModal(this)) {
                ev.preventDefault()
                return
            }
        })

        addEventListenerPoly("submit", this.inputForm, (ev) => {
            ev.preventDefault()
            this.customInputField.submit()
        })

        addEventListenerPoly("click", this.emojiButton, (ev) => {
            ev.stopPropagation()
            if (maybeShowRulesModal(this)) {
                return
            }
            // Send Pageaction for anon focusing chat on emoji click
            handleFocusedChatInput()
            standardEmojiRequest.fire(this.emojiButton)
        })
    }

    private setLineHeight(): void {
        this.messageListWrapper.style.lineHeight = `${Number(this.element.style.fontSize.slice(0, -2)) + 7}pt`
    }

    private totalInputHeight(): number {
        const topmostInputComponent = this.selectedMediaDock?.isShown() === true ? this.selectedMediaDock.element : this.inputDiv
        const chatTabContentsBoundingRect = this.element.getBoundingClientRect()
        return chatTabContentsBoundingRect.top + chatTabContentsBoundingRect.height - topmostInputComponent.getBoundingClientRect().top
    }

    public dispose(): void {
        this.listenerGroup.removeAll()
        this.emoticonAutocompleteModal.dispose()
        this.userMentionAutocompleteModal?.dispose()
        this.libraryMediaDock?.dispose()
        this.customInputField.dispose()
        this.pmControlBar?.dispose()
        this.shortcodeAutocompleteModal?.dispose()
    }

    protected shouldSendMessageFromInput(): boolean {
        if (isNotLoggedIn(i18n.loggedInToSendAMessage)) {
            return false
        }
        if (this.emoticonAutocompleteModal.isVisible() || this.userMentionAutocompleteModal?.isVisible() === true) {
            return false
        }
        if (this.shortcodeAutocompleteModal?.isVisible() === true) {
            return false
        }

        return true
    }

    // Don't call this directly, go through this.customInputField.submit() instead
    // eslint-disable-next-line complexity
    protected sendMessageFromInput(): boolean {
        if (!this.shouldSendMessageFromInput()) {
            return false
        }
        const val = this.customInputField.getText()
        const hasSelectedMedia = this.selectedMediaDock !== undefined && !this.selectedMediaDock.isEmpty()
        this.scrollToBottom()

        if (val.trim() !== "" || hasSelectedMedia) {
            this.processMessage(val)
        }

        this.recordUserAction(val)
        return true
    }

    protected shortcodeErrorMsg(shortcodeMessage: IOutgoingShortcodeMessage, raw: string): string | undefined {
        if (this.isPmChatContents) {
            return i18n.shortcodeNotSupportedInPMs
        } else if (this.inPrivateRoom()) {
            return i18n.shortcodeNotSupportedInPrivates
        } else if (shortcodeMessage.shortcodes.length === 0) {
            // Recognized as shortcode syntax, but does not contain valid shortcodes
            return errorBehindShortcode(raw)
        }
    }

    protected processMessage(val: string): void {
        const outgoingMessage = parseOutgoingMessage(val)
        switch (outgoingMessage.messageType) {
            case OutgoingMessageType.Shortcode:
                const shortcode = outgoingMessage as IOutgoingShortcodeMessage
                const errorMsg = this.shortcodeErrorMsg(shortcode, val)
                if (errorMsg !== undefined) {
                    this.appendMessageDiv(createLogMessage(errorMsg))
                } else if (this.outgoingHandlers.onShortcode) {
                    this.outgoingHandlers.onShortcode(shortcode)
                }
                break
            case OutgoingMessageType.ToggleDebugMode:
                this.outgoingHandlers.onToggleDebugMode()
                break
            case OutgoingMessageType.TipRequest:
                // `clearText` gets called in CustomInput.submit() but clear it early
                // here so the one in CustomInput doesn't mess up tip callout input focus
                this.customInputField.clearText()
                const tipMessage = outgoingMessage as ITipRequestMessage
                this.outgoingHandlers.onTipRequest(tipMessage.messageData)
                break
            default:
                this.outgoingHandlers.onChatMessage(val)
                break
        }
    }

    protected recordUserAction(val: string): void {
        const matches = val.match(/@[a-zA-Z0-9_]+/gm)
        const mentionUserList = MentionUserList.getInstance()
        if (matches !== null) {
            for (const m of matches) {
                if (mentionUserList.users().find(u => u.username === m.replace("@", "")) !== undefined) {
                    addPageAction("UserMentionMessage")
                }
            }
        }
        this.formSubmitted.fire(val)
    }

    protected repositionChildren(): void {
        this.emoticonAutocompleteModal.element.style.bottom = `${this.inputDiv.offsetHeight}px`
        if (this.userMentionAutocompleteModal !== undefined) {
            this.userMentionAutocompleteModal.element.style.bottom = `${this.inputDiv.offsetHeight}px`
        }
        if (this.shortcodeAutocompleteModal !== undefined) {
            this.shortcodeAutocompleteModal.element.style.bottom = `${this.inputDiv.offsetHeight}px`
        }

        if (!this.wasScrolledUp) {
            this.scrollToBottom()
        }
    }

    public initMediaDocks(libraryMediaDock: LibraryMediaDock): void {
        this.libraryMediaDock = libraryMediaDock
        this.selectedMediaDock = libraryMediaDock.sibling
        this.addMediaDocksToDOM()
    }

    private addMediaDocksToDOM(): void {
        if (this.libraryMediaDock !== undefined) {
            this.addChild(this.libraryMediaDock)
        }

        if (this.selectedMediaDock !== undefined) {
            this.element.insertBefore(this.selectedMediaDock.element, this.inputDiv)
        }
    }

    public isScrolledUp(): boolean {
        return this.messageListWrapper.scrollTop <= this.messageListWrapper.scrollHeight - (this.messageListWrapper.offsetHeight + 20)
    }

    public scrollToBottom(): void {
        const wasScrolledUp = this.isScrolledUp()

        if (isiOS()) {
            stopScrollMomentum(this.messageListWrapper)
        }
        this.messageListWrapper.scrollTop = this.messageListWrapper.scrollHeight
        this.messageListWrapper.scrollLeft = 0

        this.scrollDownButton?.hideElement()

        if (wasScrolledUp) {
            this.scrolledToBottom.fire()
        }
    }

    public debouncedScrollToBottom(): void {
        this.scrollToBottomDebouncer.callFunc()
    }

    public getScrollTop(): number {
        return this.messageListWrapper.scrollTop
    }

    public setScrollTop(top: number): void {
        this.messageListWrapper.scrollTo({ top: top })
    }

    private undebouncedOnScrollChange(): void {
        if (this.isScrolledUp()) {
            this.scrollDownButton?.showElement()
        } else {
            this.scrollDownButton?.hideElement()
            this.clearScrollButtonUnread()
        }
    }

    public appendNoticeDiv(c: HTMLDivElement): HTMLDivElement {
        this.noticeList.appendChild(c)
        return c
    }

    private toBottom: EventHandler = () => { this.scrollToBottom() }

    public appendMessageDiv(c: HTMLDivElement, countsForUnread = true, isFirstHistoryUnread = false): HTMLDivElement {
        const oldScrollTop = this.messageListWrapper.scrollTop
        const wasScrolledUp = this.isScrolledUp()
        if (!wasScrolledUp) {
            c.querySelectorAll("img").forEach((img) => {
                const src = img.src
                img.src = ""
                img.onload = this.toBottom
                img.src = src
            })
        }
        if (countsForUnread && (wasScrolledUp || !this.isConversationShowing())) {
            this.scrollDownButton?.incUnread()
        }
        if (this.newMessageNotice?.shouldAppendNewMessageNotice(countsForUnread, isFirstHistoryUnread) === true) {
            insertByTimestamp(this.newMessageNotice.element, this.messageList, this.isPmChatContents)
        }

        insertByTimestamp(c, this.messageList, this.isPmChatContents)
        let overflow = this.messageList.childElementCount - maxMessageHistory(this.isPmChatContents)
        for (; overflow > 0 ; overflow -= 1) {
            const firstNode = this.messageList.firstElementChild
            if (firstNode !== null) {
                this.messageList.removeChild(firstNode)
            }
        }
        if (!wasScrolledUp) {
            this.scrollToBottom()
        } else {
            this.newMessageNotice?.maybeScrollJump(oldScrollTop)
        }
        this.messageCounter += 1

        resetPureChatHideTimeouts([c])

        return c
    }

    public clearScrollButtonUnread(): void {
        this.scrollDownButton?.clearUnread()
    }

    public showElement(): void {
        super.showElement("flex")
    }

    public removeMessageDiv(c: HTMLDivElement): void {
        this.messageList.removeChild(c)
    }

    public getLastMessageId(): number {
        return this.messageCounter
    }

    public messagesSinceId(id: number): number {
        return this.messageCounter - id
    }

    public getEarliestMessageId(): string | undefined {
        return this.earliestMessageId
    }

    public setEarliestMessageId(newMessageId: string): void {
        this.earliestMessageId = newMessageId
    }

    public clear(): void {
        this.messageCounter = 0
        while (this.messageList.firstChild !== null) {
            this.messageList.removeChild(this.messageList.firstChild)
        }
    }

    public focusCurrentChatInput(): void {
        if (!isElementInViewport(this.customInputField.element)) {
            scrollToTheaterModePlayer.fire()
        }
        this.customInputField.focus(false, false)
    }

    public blurCurrentChatInput(): void {
        this.customInputField.blur()
    }

    public removeMessagesForUser(c: string): void {
        this.removeMessagesForUserEvent.fire(c)
    }

    public isInputFocused(): boolean {
        return document.activeElement === this.customInputField.element
    }

    public getInputText(): string {
        return this.customInputField.getText()
    }

    public setInputText(text: string): void {
        this.customInputField.setText(text)
    }

    public appendInputText(text: string): void {
        this.customInputField.appendText(text)
    }
}
