import { RoomListSource } from "@multimediallc/web-utils/types"
import {
    determineRoomLabel, getRecommendedDisplayLabel,
    MoreRoomRecommendationSources,
} from "../../cb/components/roomlist/moreRooms/moreRoomRecommendationSources"
import { saveRoomListSourceCookie } from "../../cb/roomList"
import { addEventListenerMultiPoly, addEventListenerPoly } from "../addEventListenerPolyfill"
import { normalizeResource } from "../api"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { RoomImageStreamer } from "../roomImage"
import { loadRoomRequest } from "./userActionEvents"

export const roomImageHeightRatio = 270 / 480
export const roomPadding = 4


export class Room extends Component {
    private roomImage: HTMLImageElement
    private roomTitle: HTMLDivElement
    private roomLink: HTMLAnchorElement
    private streamer: RoomImageStreamer

    constructor(private roomName: string, roomIndex: number, sourceInfo?: string) {
        super()
        this.element.style.position = "relative"
        this.element.style.padding = `${roomPadding}px`

        this.roomLink = document.createElement("a")
        this.roomLink.href = normalizeResource(`/${roomName}/`)
        this.element.appendChild(this.roomLink)
        addEventListenerPoly("click", this.roomLink, (e: MouseEvent) => {
            // allow opening room in new tab/window with key + click
            if (!e.metaKey && !e.ctrlKey && !e.shiftKey) {
                loadRoomRequest.fire(this.roomName)
                e.preventDefault()
            }
        })
        addEventListenerMultiPoly(["click", "mousedown", "touchstart"], this.roomLink, (e: UIEvent) => {
            saveRoomListSourceCookie(this.roomName, RoomListSource.MoreRooms, roomIndex, { sourceInfo: sourceInfo })
        })

        this.roomImage = document.createElement("img")

        this.roomImage.style.height = "100%"
        this.roomImage.style.width = "100%"
        this.roomImage.style.cursor = "pointer"
        this.roomImage.className = "moreRooms"
        this.roomImage.id = `roomImage${roomIndex}`
        this.roomImage.dataset.testid = "more-rooms-thumbnail"
        this.roomLink.appendChild(this.roomImage)

        const roomTitleStyle: CSSX.Properties = {
            position: "absolute",
            bottom: "7px",
            color: "#ffffff",
            fontSize: "19px",
            textShadow: "1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000",
        }

        this.roomTitle = document.createElement("div")
        this.roomTitle.innerText = roomName
        applyStyles(this.roomTitle, roomTitleStyle)
        this.roomTitle.classList.add(`roomTitle${roomIndex}`)
        this.roomTitle.dataset.testid = "more-rooms-title"
        this.roomLink.appendChild(this.roomTitle)

        const potentialRecommendationLabel = determineRoomLabel(sourceInfo)
        if (potentialRecommendationLabel !== MoreRoomRecommendationSources.unknown) {
            const roomLabelStyle: CSSX.Properties = {
                position: "absolute",
                top: "12px",
                color: "#ffffff",
                fontSize: "11px",
                right: "12px",
            }
            const roomLabel = document.createElement("div")
            roomLabel.className = "thumbnail_label_recommender"
            roomLabel.innerText = getRecommendedDisplayLabel(potentialRecommendationLabel)
            applyStyles(roomLabel, roomLabelStyle)
            this.roomLink.appendChild(roomLabel)
        }

        this.streamer = new RoomImageStreamer(this.roomName, this.roomImage)

        this.element.onmouseenter = () => {
            this.streamer.startStreaming()
        }

        this.element.onmouseleave = () => {
            this.streamer.stopStreaming()
        }
    }

    protected repositionChildren(): void {
        if (this.parent !== undefined) {
            this.roomTitle.style.left = `${this.parent.element.offsetWidth / 2 - this.roomTitle.offsetWidth / 2}px`
        } else {
            error("Room does not have a parent")
        }
    }

    reloadImage(): void {
        this.streamer.loadImage(0).catch(err => {
            warn("Error loading image", { imageSrc: err })
        })
    }

    getRoomName(): string {
        return this.roomName
    }

    protected afterRemovedFromParent(): void {
        this.streamer.stopStreaming()
        super.afterRemovedFromParent()
    }

    safariReloadImage(): void {
        this.streamer.safariReloadImage()
    }
}
