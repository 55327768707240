import { RoomStatus } from "@multimediallc/web-utils"
import { tokenBalanceUpdate } from "../../cb/api/tipping"
import { addColorClass } from "../../cb/colorClasses"
import { roomLoaded } from "../context"
import { Component } from "../defui/component"
import {
    enterPrivateShowAlertChain,
    leavePrivateOrSpyShowAlertChain,
} from "../privateShow"
import { i18n } from "../translation"
import type { IChatConnection } from "../context"

export class PrivateShowComponent extends Component {
    private privateShowComponent?: StartShowComponent
    private cancelShowComponent?: CancelShowComponent
    chatConnection: IChatConnection

    constructor(private onComponentsUpdate: () => void) {
        super()
        this.element.style.position = "static"
        this.element.style.overflow = "auto"
        this.element.style.padding = "4px 7px"
        this.element.style.boxSizing = "border-box"
        this.element.style.fontSize = "12px"
        this.element.style.minWidth = "230px"
        this.element.style.maxWidth = "230px"
        this.element.style.height = "auto"

        for (const child of this.children()) {
            child.element.style.margin = "2px 0"
        }

        roomLoaded.listen(context => {
            let allowPrivateShow = context.dossier.allowPrivateShow
            this.showComponentsForStatus(allowPrivateShow, context.chatConnection.status)
            context.chatConnection.event.statusChange.listen((statusChange) => {
                this.showComponentsForStatus(allowPrivateShow, statusChange.currentStatus)
            })
            context.chatConnection.event.settingsUpdate.listen((newSettings) => {
                allowPrivateShow = newSettings.allowPrivateShow
                this.showComponentsForStatus(allowPrivateShow, context.chatConnection.status)
            })
        })
    }

    public showComponentsForStatus(allowPrivateShow: boolean, status: RoomStatus): void {
        this.removeAllChildren()
        if (this.inPrivate(status)) {
            if (this.cancelShowComponent === undefined) {
                this.cancelShowComponent = new CancelShowComponent()
            }
            this.addChild(this.cancelShowComponent)
        } else {
            if (this.shouldShowPrivateShowComponent(allowPrivateShow, status)) {
                if (this.privateShowComponent === undefined) {
                    this.privateShowComponent = new StartShowComponent(this.onComponentsUpdate)
                }
                this.addChild(this.privateShowComponent)
            }
        }
        this.onComponentsUpdate()
    }

    private shouldShowPrivateShowComponent(allowPrivateShow: boolean, status: RoomStatus): boolean {
        switch (status) {
            case RoomStatus.PrivateRequesting:
                return true
            case RoomStatus.PrivateSpying:
            case RoomStatus.PrivateWatching:
            case RoomStatus.PrivateNotWatching:
            case RoomStatus.Away:
                return false
            default:
                return allowPrivateShow
        }
    }

    private inPrivate(status: RoomStatus): boolean {
        switch (status) {
            case RoomStatus.PrivateWatching:
            case RoomStatus.PrivateSpying:
                return true
            default:
                return false
        }
    }
}

abstract class BaseComponent extends Component {
    protected constructor() {
        super()
        this.element.style.display = "inline-block"
        this.element.style.height = "auto"
        this.element.style.position = "relative"
        this.element.style.padding = "5px 5px"
        this.element.style.borderWidth = "1px"
        this.element.style.borderStyle = "solid"
        this.element.style.boxSizing = "border-box"
        this.element.style.borderRadius = "3px"
        this.element.style.font = "UbuntuRegular, Arial, Helvetica, sans-serif"
        this.element.style.fontSize = "12px"
        this.element.style.lineHeight = "14px"
        this.element.style.textAlign = "center"
    }
}

function baseHeader(text: string): HTMLDivElement {
    const header = document.createElement("div")
    addColorClass(header, "showsHeader")
    header.innerText = text
    header.style.width = "100%"
    header.style.margin = "5px 0"
    header.style.fontSize = "14px"
    header.style.fontFamily = "UbuntuMedium, Arial, Helvetica, sans-serif"
    header.style.fontWeight = "500"
    header.style.letterSpacing = "-0.05em"
    return header
}

function baseButton(text: string): HTMLDivElement {
    const button = document.createElement("div")
    addColorClass(button, "showsButton")
    button.style.display = "inline-block"
    button.innerText = text
    button.style.borderWidth = "1px"
    button.style.borderStyle = "solid"
    button.style.boxSizing = "border-box"
    button.style.borderRadius = "4px"
    button.style.fontWeight = "bold"
    button.style.padding = "6px 10px"
    button.style.margin = "5px 0"
    button.style.cursor = "pointer"
    button.style.textDecoration = "none"
    return button
}

class StartShowComponent extends BaseComponent {
    readonly header = baseHeader(i18n.privateShowText)
    readonly button = baseButton(i18n.privateShowStartLabelCAPS)
    readonly privateShowRequirements: HTMLDivElement
    readonly tokensRequirement: HTMLDivElement
    readonly minimumMinutesRequirement: HTMLDivElement
    readonly privateFooter: HTMLDivElement
    private privatePrice = 0
    private privateMinMinutes = 0
    private allowShowRecordings = false
    private premiumPrivatePrice = 0
    private premiumPrivateMinMinutes = 0
    private premiumChosen = false

    constructor(private onComponentsUpdate: () => void) {
        super()
        addColorClass(this.element, "startShowComponent")
        this.element.appendChild(this.header)
        this.privateShowRequirements = document.createElement("div")
        this.privateShowRequirements.style.margin = "5px 0"
        this.privateShowRequirements.style.lineHeight = "16px"
        this.privateShowRequirements.style.letterSpacing = "-0.05em"
        this.tokensRequirement = document.createElement("div")
        addColorClass(this.tokensRequirement, "tokensRequirement")
        this.tokensRequirement.style.fontFamily = "UbuntuMedium, Arial, Helvetica, sans-serif"
        this.privateShowRequirements.appendChild(this.tokensRequirement)
        this.minimumMinutesRequirement = document.createElement("div")
        addColorClass(this.minimumMinutesRequirement, "minutesRequirement")
        this.privateShowRequirements.appendChild(this.minimumMinutesRequirement)
        this.element.appendChild(this.privateShowRequirements)
        this.element.appendChild(this.button)

        this.privateFooter = document.createElement("div")
        addColorClass(this.privateFooter, "showsFooter")
        this.privateFooter.innerText = i18n.broadcasterAllowsRecordingsText
        this.privateFooter.style.fontSize = "9px"
        this.privateFooter.style.lineHeight = "125%"
        this.privateFooter.style.margin = "5px 0"
        this.element.appendChild(this.privateFooter)
        roomLoaded.listen(context => {
            this.updatePrivateShowInfo(context.dossier.allowShowRecordings, context.dossier.privatePrice, context.dossier.privateMinMinutes, context.dossier.premiumPrivatePrice, context.dossier.premiumPrivateMinMinutes)
            this.update()
            context.chatConnection.event.settingsUpdate.listen(update => {
                this.updatePrivateShowInfo(update.allowShowRecordings, update.privatePrice, update.privateMinMinutes, update.premiumPrivatePrice, update.premiumPrivateMinMinutes)
                switch (context.chatConnection.status) {
                    case RoomStatus.PrivateRequesting:
                        // We don't want to update the values for the viewer here because if the viewer is in a
                        // requesting state that holds previous values before the change.  If a show is accepted
                        // It will be on the previous values not the new values
                        return
                    default:
                        this.update()
                }
            })
            context.chatConnection.event.statusChange.listen(roomStatus => {
                if (roomStatus.currentStatus === RoomStatus.PrivateRequesting) {
                    this.premiumChosen = context.chatConnection.premiumRequested
                    this.button.innerText = i18n.privateShowRequestCancelMessageCAPS
                    this.button.onclick = () => {
                        leavePrivateOrSpyShowAlertChain(context.chatConnection)
                    }
                } else {
                    this.button.innerText = i18n.privateShowStartLabelCAPS
                    this.button.onclick = () => {
                        enterPrivateShowAlertChain(context.chatConnection)
                    }
                }
                this.update()
            })
        })
    }

    public updatePrivateShowInfo(allowRecordings: boolean, privatePrice: number, minimumMinutes: number, premiumPrivatePrice: number, premiumMinimumMinutes: number): void {
        this.allowShowRecordings = allowRecordings
        this.privatePrice = privatePrice
        this.privateMinMinutes = minimumMinutes
        this.premiumPrivatePrice = premiumPrivatePrice
        this.premiumPrivateMinMinutes = premiumMinimumMinutes
    }

    private update(): void {
        const price = this.premiumChosen ? this.premiumPrivatePrice : this.privatePrice
        const minMinutes = this.premiumChosen ? this.premiumPrivateMinMinutes : this.privateMinMinutes
        this.tokensRequirement.innerText = i18n.priceTokensPerMinuteMessage(price)
        this.minimumMinutesRequirement.innerText = `(${i18n.privateShowMinimumMinuteMessage(minMinutes)})`
        if (this.privateMinMinutes === 0) {
            this.minimumMinutesRequirement.style.display = "none"
        } else {
            this.minimumMinutesRequirement.style.display = "block"
        }
        if (this.allowShowRecordings) {
            this.privateFooter.style.display = "block"
        } else {
            this.privateFooter.style.display = "none"
        }
        this.onComponentsUpdate()
    }
}

class CancelShowComponent extends BaseComponent {
    public tokenTextArea = document.createElement("div")
    private balance: number

    constructor() {
        super()

        addColorClass(this.element, "cancelShowComponent")
        this.element.style.minWidth = "200px"
        this.element.style.padding = ""
        this.element.style.borderStyle = "none"

        this.tokenTextArea.style.display = "block"
        this.tokenTextArea.style.fontSize = "16px"
        this.tokenTextArea.style.lineHeight = "18px"
        this.tokenTextArea.style.margin = "5px 0"
        addColorClass(this.tokenTextArea, "tokenTextArea")
        this.element.appendChild(this.tokenTextArea)

        const button = document.createElement("div")
        addColorClass(button, "cancelShowButton")
        addColorClass(button, "cancelShowButtonGradient")
        button.style.display = "inline-block"
        button.style.boxSizing = "border-box"
        button.style.borderRadius = "4px"
        button.style.padding = "6px 10px"
        button.style.margin = "5px 0"
        button.style.cursor = "pointer"
        button.style.textDecoration = "none"
        this.element.appendChild(button)

        roomLoaded.listen((context) => {
            this.updateTokenCount(context.dossier.tokenBalance)
            context.chatConnection.event.tipAlert.listen(ev => {
                if (ev.fromUser.username === context.chatConnection.username()) {
                    this.updateTokenCount(this.balance - ev.amount)
                }
            })

            context.chatConnection.event.statusChange.listen(statusUpdate => {
                const confirm = context.chatConnection.privateMinEnd < Date.now()
                const leavePrivateOrSpyConfirm = () => {
                    leavePrivateOrSpyShowAlertChain(context.chatConnection, confirm)
                }
                switch (statusUpdate.currentStatus) {
                    case RoomStatus.PrivateWatching:
                        button.innerText = i18n.privateShowLeaveLabelCAPS
                        button.onclick = leavePrivateOrSpyConfirm
                        break
                    case RoomStatus.PrivateSpying:
                        button.innerText = i18n.privateShowSpyLeaveLabelCaps
                        button.onclick = leavePrivateOrSpyConfirm
                        break
                    default:
                        button.onclick = () => {}
                }
            })
        })

        tokenBalanceUpdate.listen((ev) => {
            this.updateTokenCount(ev.tokens)
        })
    }

    private updateTokenCount(tokens: number): void {
        this.balance = tokens
        this.tokenTextArea.innerText = `${tokens} ${i18n.tokenOrTokensText(tokens, false)}`
    }
}
