import { RoomStatus } from "@multimediallc/web-utils"
import { pageContext } from "../../cb/interfaces/context"
import { AbuseReport } from "../abuse"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { modalAlert } from "../alerts"
import { isNotLoggedIn } from "../auth"
import { roomLoaded } from "../context"
import { Component } from "../defui/component"
import { getScrollbarWidth } from "../DOMutils"
import { EventRouter } from "../events"
import { addPageAction } from "../newrelic"
import { OrderedSet } from "../orderedSet"
import { i18n } from "../translation"
import { parseQueryString } from "../urlUtil"
import { mentionUser, roomListRequest, userInitiatedPm } from "../userActionEvents"
import { AnonymousWelcome } from "./anonymousWelcome"
import { BioContent } from "./bioContent"
import { DraggableCanvasChatWindow } from "./draggableCanvasChatWindow"
import { DraggableCanvasWindow, windowBorder } from "./draggableCanvasWindow"
import { PrivateShowComponent } from "./privateShow"
import { DesktopMoreRoomsList } from "./roomList"
import { TipCallout } from "./tipCallout"
import { chatWindowRequest, openBioRequest, openTipCalloutRequest, privateWindowRequest, reportAbuseRequest, userListRequest } from "./userActionEvents"
import { UserList } from "./userList"
import { WindowKey } from "./windowKey"
import { WindowPositionerAnchors } from "./windowPositioner"
import type { IRoomContext } from "../context"
import type { IRoomStatusChangeNotification } from "../messageInterfaces"
import type { ITipRequest } from "../specialoutgoingmessages"
import type { SessionMetrics } from "../theatermodelib/sessionMetrics"
import type { ITipSent } from "../theatermodelib/tipCallout"

const allMinWindowHeight = 100

// DraggableCanvas is the main "WindowManager"
export class DraggableCanvas extends Component {
    private components: Component[] = []
    private anonymousWelcome: AnonymousWelcome | undefined
    chatWindow: DraggableCanvasChatWindow
    userWindow?: DraggableCanvasWindow
    private userList?: UserList
    private tipCallout?: TipCallout
    tipWindow?: DraggableCanvasWindow
    moreRoomsWindow?: DraggableCanvasWindow
    abuseReportWindow?: DraggableCanvasWindow
    private abuseReport?: AbuseReport
    private privateShowComponent?: PrivateShowComponent
    privateWindow?: DraggableCanvasWindow
    windowsStack = new OrderedSet<DraggableCanvasWindow>()
    focusedWindow: DraggableCanvasWindow | undefined
    hoveredWindow: DraggableCanvasWindow | undefined
    focusChanging = new EventRouter<DraggableCanvasWindow | undefined>("focusChanging", { reportIfNoListeners: false })
    focusChanged = new EventRouter<DraggableCanvasWindow | undefined>("focusChanged", { reportIfNoListeners: false })
    windowOpened = new EventRouter<DraggableCanvasWindow>("windowOpened", { reportIfNoListeners: false })
    windowClosed = new EventRouter<DraggableCanvasWindow>("windowClosed", { reportIfNoListeners: false })
    windowHover = new EventRouter<DraggableCanvasWindow | undefined>("windowHover", { reportIfNoListeners: false })
    private resizing: boolean

    constructor(private source = "fullvideo", public sessionMetrics?: SessionMetrics) {
        super()

        this.element.style.overflow = ""
        this.element.style.pointerEvents = "none"

        this.chatWindow = this.addWindow(new DraggableCanvasChatWindow(this))
        this.chatWindow.element.dataset.testid = "chat-floating-window"
        userInitiatedPm.listen((notification) => {
            if (!this.chatWindow.isOpen) {
                this.chatWindow.element.style.display = ""
                this.chatWindow.isOpen = true
            }
            if (notification.focus) {
                this.setFocusedWindow(this.chatWindow)
            }
            this.chatWindow.repositionChildrenRecursive()
        })

        userListRequest.listen(() => {
            if (this.userWindow !== undefined && this.userWindow.isOpen) {
                this.removeChild(this.userWindow)
                return
            }
            const windowKey = WindowKey.Users
            this.removeChildByName(windowKey)
            const usersPrefix = i18n.usersText
            this.userList = new UserList()
            let first = true
            const roomLoadedListener = (context: IRoomContext) => {
                if (!first) {
                    this.userList?.clear(context.dossier.numViewers)
                }
                first = false
            }
            roomLoaded.listen(roomLoadedListener)
            const userListWindow = this.addWindow(new DraggableCanvasWindow(this, this.userList, {
                title: usersPrefix,
                resizeable: true,
                windowKey: windowKey,
                minWidth: 100,
                minHeight: allMinWindowHeight,
                defaultWidth: 190,
                defaultHeight: 230,
                defaultAnchors: [WindowPositionerAnchors.Left, WindowPositionerAnchors.Top],
            }))
            userListWindow.element.dataset.testid = "user-list-floating-window"
            this.userList.element.style.overflowY = "auto"
            this.repositionChildrenRecursive()
            this.userList.userCountUpdate.listen((userCount: number) => {
                userListWindow.setTitle(`${usersPrefix} (${userCount})`)
            })
            userListWindow.onClose = () => {
                roomLoaded.removeListener(roomLoadedListener)
            }
            this.userWindow = userListWindow
        })

        chatWindowRequest.listen(() => {
            if (this.chatWindow.isOpen) {
                this.chatWindow.element.style.display = "none"
                this.chatWindow.isOpen = false
            } else {
                this.chatWindow.element.style.display = ""
                this.chatWindow.isOpen = true
                this.setFocusedWindow(this.chatWindow)
            }
            this.repositionChildrenRecursive()
        })

        mentionUser.listen(() => {
            if (!this.chatWindow.isOpen) {
                chatWindowRequest.fire(undefined)
            }
            this.setFocusedWindow(this.chatWindow)
            this.hoveredWindow = this.chatWindow
            this.chatWindow.chatTabContainer.chatTab.focusCurrentChatInput()
        })

        roomListRequest.listen(() => {
            if (this.moreRoomsWindow !== undefined && this.moreRoomsWindow.isOpen) {
                this.removeChild(this.moreRoomsWindow)
                return
            }
            this.openRoomList()
            // repositionChildren here but not inside `openRoomList` so that it doesn't trigger on startup
            this.repositionChildrenRecursive()
        })

        openBioRequest.listen((username) => {
            const roomLoadedListener = (context: IRoomContext) => {
                bioWindow.setTitle(context.dossier.room)
            }
            const windowKey = WindowKey.Bio
            this.removeChildByName(windowKey)
            const bioContent = new BioContent(username)
            const bioWindow = this.addWindow(new DraggableCanvasWindow(this, bioContent, {
                title: username,
                resizeable: true,
                windowKey: windowKey,
                defaultHeight: 400,
                defaultWidth: 360,
                minWidth: 360,
                minHeight: allMinWindowHeight,
                defaultAnchors: [WindowPositionerAnchors.Left, WindowPositionerAnchors.Top],
                onClose: () => {
                    roomLoaded.removeListener(roomLoadedListener)
                },
            }))
            roomLoaded.listen(roomLoadedListener, false)
            this.repositionChildrenRecursive()
        })
        let isAgeVerified: boolean
        roomLoaded.listen((context: IRoomContext) => {
            isAgeVerified = context.dossier.isAgeVerified
        })

        const tipsCanBeTransfered = (): boolean => {
            if (!isAgeVerified) {
                modalAlert("This broadcaster doesn't accept tips.")
                return false
            }
            if (pageContext.current.isNoninteractiveUser){
                modalAlert(i18n.internalStaffTip)
                return false
            }
            return !isNotLoggedIn("You must be logged in to tip. Click \"OK\" to login.")
        }
        let lastTipAmount: number | undefined
        openTipCalloutRequest.listen((tipRequest: ITipRequest) => {
            if (tipRequest.amount === undefined) {
                if (this.removeTipWindowIfOpen(tipRequest)) {
                    return
                }
            }

            addPageAction("OpenTipCallout")

            if (this.tipCallout === undefined) {
                this.tipCallout = new TipCallout(this.source)
                this.tipCallout.notifyAttemptSendTip.listen((event: ITipSent) => {
                    lastTipAmount = event.amount
                    if (this.tipWindow !== undefined) {
                        openTipCalloutRequest.fire({})
                    }
                })

                this.tipCallout.shouldReposition.listen(() => {
                    if (this.tipWindow !== undefined && this.tipCallout !== undefined) {
                        const [contentWidth, contentHeight] = this.tipCallout.getContentSize()
                        this.tipWindow.resize(contentHeight, contentWidth)
                        this.repositionChildrenRecursive()
                    }
                })
            }

            if (tipRequest.amount === undefined) {
                tipRequest.amount = lastTipAmount
            }
            const roomLoadedListener = () => {
                if (this.tipWindow !== undefined) {
                    this.removeChild(this.tipWindow)
                }
            }
            if (!tipsCanBeTransfered()) {
                return
            }
            if (this.sessionMetrics !== undefined) {
                this.sessionMetrics.bindTipCallout(this.tipCallout)
            }
            this.tipCallout.show(tipRequest)
            if (this.tipWindow !== undefined) {
                this.setFocusedWindow(this.tipWindow)
                return
            }
            const [contentWidth, contentHeight] = this.tipCallout.getContentSize()
            this.tipWindow = this.addWindow(new DraggableCanvasWindow(this, this.tipCallout, {
                title: i18n.sendTipText,
                resizeable: false,
                windowKey: WindowKey.Tip,
                minWidth: contentWidth + 2 * windowBorder,
                minHeight: contentHeight + 2 * windowBorder,
                defaultWidth: contentWidth,
                autoSize: true,
                defaultHeight: contentHeight,
                defaultAnchors: [WindowPositionerAnchors.Center],
                onClose: () => {
                    roomLoaded.removeListener(roomLoadedListener)
                    this.tipWindow = undefined
                },
                onGainedFocus: () => {
                    if (this.tipCallout !== undefined) {
                        this.tipCallout.focusTipAmount()
                    }
                },
                onKey: (event: KeyboardEvent) => {
                    if (this.tipWindow === undefined) {
                        error("undefined tipwindow")
                        return true
                    }
                    if (event.key === "Escape") {
                        this.removeChild(this.tipWindow)
                    } else if (event.key === "s" && (event.ctrlKey || event.metaKey)) {
                        this.removeChild(this.tipWindow)
                        event.preventDefault()
                    }
                    return true // eat keyboard events
                },
            }))
            this.tipWindow.innerDiv.style.boxSizing = ""
            this.tipWindow.element.dataset.testid = "floating-tip-window"

            roomLoaded.listen(roomLoadedListener, false)
            this.repositionChildrenRecursive()
            this.tipCallout.focusTipAmount(true)
        })

        roomLoaded.listen((context: IRoomContext) => {
            // hide private on room change
            if (this.privateWindow !== undefined && this.privateWindow.isOpen) {
                this.removeChild(this.privateWindow)
            }
            let currentStatus = context.chatConnection.status
            const shouldRemove = !context.dossier.isAgeVerified || !context.dossier.allowPrivateShow

            // hide private on status that does not allow private requests
            // eslint-disable-next-line complexity
            context.chatConnection.event.statusChange.listen(statusChangeNotification => {
                currentStatus = statusChangeNotification.currentStatus
                switch (statusChangeNotification.currentStatus) {
                    case RoomStatus.Unknown:
                    case RoomStatus.Offline:
                    case RoomStatus.NotConnected:
                    case RoomStatus.Away:
                    case RoomStatus.Hidden:
                    case RoomStatus.PrivateNotWatching:
                    case RoomStatus.PasswordProtected:
                        if (this.privateWindow !== undefined && this.privateWindow.isOpen) {
                            this.removeChild(this.privateWindow)
                            return
                        }
                        break
                    case RoomStatus.PrivateRequesting:
                    case RoomStatus.PrivateWatching:
                    case RoomStatus.PrivateSpying:
                        break
                    default:
                        if (shouldRemove && this.privateWindow !== undefined && this.privateWindow.isOpen) {
                            this.removeChild(this.privateWindow)
                            return
                        }
                }
                this.roomListOnStatusChange(statusChangeNotification)
            })
            const keepShowingPrivateStatuses = [RoomStatus.PrivateRequesting, RoomStatus.PrivateSpying, RoomStatus.PrivateWatching]
            context.chatConnection.event.settingsUpdate.listen(newSettings => {
                if (!newSettings.allowPrivateShow && keepShowingPrivateStatuses.indexOf(currentStatus) === -1) {
                    if (this.privateWindow !== undefined && this.privateWindow.isOpen) {
                        this.removeChild(this.privateWindow)
                        return
                    }
                }
            })
        })

        reportAbuseRequest.listen(() => {
            if (this.abuseReportWindow !== undefined && this.abuseReportWindow.isOpen) {
                this.removeChild(this.abuseReportWindow)
                return
            }
            if (this.abuseReportWindow !== undefined) {
                this.removeChild(this.abuseReportWindow)
            }
            this.abuseReport = new AbuseReport()
            this.abuseReportWindow = this.addWindow(new DraggableCanvasWindow(this, this.abuseReport, {
                title: i18n.reportAbuseText,
                resizeable: true,
                windowKey: WindowKey.Abuse,
                minWidth: 270,
                minHeight: 200,
                defaultWidth: 270,
                defaultHeight: 200,
                defaultAnchors: [WindowPositionerAnchors.Bottom, WindowPositionerAnchors.Right],
                onGainedFocus: () => {
                    if (this.abuseReport !== undefined) {
                        this.abuseReport.focusCategory()
                    }
                },
                onKey: (event: KeyboardEvent) => {
                    return true // eat keyboard events
                },
                onClose: () => {
                    this.abuseReportWindow = undefined
                },
            }))
            this.repositionChildrenRecursive()

            this.abuseReport.closeReportAbuseRequest.listen(() => {
                if (this.abuseReportWindow !== undefined) {
                    this.removeChild(this.abuseReportWindow)
                }
            })
        })

        privateWindowRequest.listen(() => {
            if (this.privateWindow !== undefined && this.privateWindow.isOpen) {
                this.removeChild(this.privateWindow)
                return
            }

            if (this.privateShowComponent === undefined) {
                this.privateShowComponent = new PrivateShowComponent(() => {
                    if (this.privateWindow !== undefined && this.privateWindow.isOpen) {
                        this.privateWindow.positioner.apply()
                    }
                })
            }

            this.privateWindow = this.addWindow(
                new DraggableCanvasWindow(this, this.privateShowComponent, {
                    title: i18n.privateShows,
                    resizeable: false,
                    windowKey: WindowKey.Private,
                    autoSize: true,
                    minWidth: 248,
                    minHeight: 270,
                    defaultWidth: 248,
                    defaultHeight: 270,
                    defaultAnchors: [WindowPositionerAnchors.Top, WindowPositionerAnchors.Right],
                }))

            this.privateWindow.element.dataset.testid = "private-shows-floating-window"
            this.repositionChildrenRecursive()
        })


        this.anonymousWelcome = new AnonymousWelcome()
        this._addChild(this.anonymousWelcome)

        if (parseQueryString(window.location.search)["room_list"] === "1") {
            this.openRoomList()
        }

        this.setFocusedWindow(undefined)  // don't auto focus the chat
    }

    private openRoomList(): void {
        const windowKey = WindowKey.Rooms
        this.removeChildByName(windowKey)
        const roomList = new DesktopMoreRoomsList()
        const minWidth = 225 + getScrollbarWidth()
        this.moreRoomsWindow = this.addWindow(new DraggableCanvasWindow(this, roomList, {
            title: i18n.moreRoomsText,
            resizeable: true,
            windowKey: windowKey,
            minWidth: minWidth,
            minHeight: allMinWindowHeight,
            defaultWidth: minWidth,
            defaultHeight: 385,
            defaultAnchors: [WindowPositionerAnchors.Right, WindowPositionerAnchors.Top],
            onClose: () => {
                roomList.close()
            },
        }))
        this.moreRoomsWindow.element.dataset.testid = "more-rooms-floating-window"
        addPageAction("MoreRoomsOpened")
    }

    private roomListOnStatusChange(status: IRoomStatusChangeNotification): void {
        if (this.userList === undefined) {
            return
        }
        const cameFromOrEnteredPrivateChat = status.previousStatus === RoomStatus.PrivateWatching ||
            status.currentStatus === RoomStatus.PrivateWatching
        if (cameFromOrEnteredPrivateChat) {
            window.setTimeout(() => {
                this.userList?.refresh()
            }, 2000) // Delay to ensure user is connected to room.
        }
        if (status.previousStatus === RoomStatus.NotConnected) {
            this.userList.refresh()
            this.userList.element.scrollTop = 0
        }
    }

    public repositionChildren(): void {
        super.repositionChildren()
        for (const child of this.windowsStack.toArray()) {
            // do this here instead of inside the draggableCanvasWindow because two reasons
            // 1) more pure. we should be positioning our children, they shouldn't position themselves
            // 2) prevents infinite loops where resizing causes a window to fire reposition, which then tells
            // the positioner to apply
            child.positioner.apply()
        }

        if (this.anonymousWelcome !== undefined) {
            this.anonymousWelcome.element.style.left = `${(this.element.clientWidth - this.anonymousWelcome.element.offsetWidth) / 2}px`
        }
    }

    setFocusedWindow(w: DraggableCanvasWindow | undefined): void {
        if (this.resizing) {
            // no focus changes during resize
            return
        }

        if (this.focusedWindow === w) {
            return
        }
        if (this.focusedWindow !== undefined) {
            this.focusedWindow.lostFocus()
            if (document.activeElement !== document.body) {
                if (document.activeElement !== undefined && document.activeElement !== null) { // IE
                    try {
                        (document.activeElement as HTMLElement).blur()
                    } catch (error) {
                        debug(error)
                    }
                }
            }
        }

        this.focusChanging.fire(w)

        if (w !== undefined) {
            addPageAction("FocusWindow", { "windowName": w.getWindowKey() })
            debug(`Setting focus to ${w.getWindowKey()}`)
            this.moveToFront(w)
            w.gainedFocus()
        }

        this.focusedWindow = w
        this.focusChanged.fire(w)
    }

    addChild<CT extends Component>(c: CT, appendToDiv?: HTMLDivElement): CT {
        throw new Error("addChild not supported")
    }

    private _addChild<CT extends Component>(c: CT, appendToDiv?: HTMLDivElement): CT {
        c.element.style.pointerEvents = "auto"
        return super.addChild(c, appendToDiv)
    }

    addWindow<CT extends DraggableCanvasWindow>(c: CT, appendToDiv?: HTMLDivElement): CT {
        c.draggingOrResizingChanged.listen((on) => {
            this.resizing = on
        })
        addEventListenerPoly("mousedown", c.element, ev => {
            debug(`onclick ${c.getWindowKey()}`)
            if (this.focusedWindow !== c) {
                this.setFocusedWindow(c)
                const targetIsInput = ev.target instanceof HTMLElement && (["input", "textarea"].includes(ev.target.tagName.toLowerCase()) || ev.target.contentEditable === "true")
                if (!targetIsInput) {
                    ev.preventDefault() // don't let browser change focus after we're done
                }
            }
        })
        addEventListenerPoly("mouseover", c.element, ev => {
            this.hoveredWindow = c
            this.windowHover.fire(c)
        })
        this.windowsStack.addToTop(c)
        this._addChild(c, appendToDiv)
        this.setFocusedWindow(c)
        this.windowOpened.fire(c)
        c.isOpen = true
        return c
    }

    addComponent(p: Component): Component {
        this.components.push(p)
        this._addChild(p)
        return p
    }

    removeChild(c: DraggableCanvasWindow): void {
        c.isOpen = false
        if (c.onClose !== undefined) {
            c.onClose()
        }
        this.setFocusedWindow(undefined)
        this.windowsStack.remove(c)
        super.removeChild(c)
        this.windowClosed.fire(c)
    }

    moveToFront(draggableCanvasWindow: DraggableCanvasWindow): void {
        this.windowsStack.addToTop(draggableCanvasWindow)
        this.setChildZindexes()
    }

    setChildZindexes(): void {
        let zIndex = this.children().length + 10
        for (const c of this.windowsStack.toArray()) {
            c.element.style.zIndex = `${zIndex}`
            zIndex -= 1
        }
        for (const c of this.components) {
            c.element.style.zIndex = `${zIndex}`
            zIndex -= 1
        }
    }

    removeChildByName(windowKey: string): void {
        for (const child of this.windowsStack.toArray()) {
            if (child.getWindowKey() === windowKey) {
                this.removeChild(child)
                if (child === this.focusedWindow) {
                    this.setFocusedWindow(undefined)
                }
                break
            }
        }
    }

    removeTipWindowIfOpen(tipRequest: ITipRequest): boolean {
        let removed = false
        if (this.tipWindow !== undefined && this.tipWindow.isOpen) {
            if (this.tipCallout !== undefined && !this.tipCallout.stayOpen) {
                this.removeChild(this.tipWindow)
                removed = true
            }
        }
        return removed
    }
}
